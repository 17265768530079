import { useEffect, useState } from "react";
import {
  connectWallet,
  getCurrentWalletConnected,
  claimMagicWord,
  check1,
  check2,
  check3,
  check4,
  check5
} from "./utils/interact.js";
import example from 'D:/NFT_development/front_end/polypixa-metacache/nft-minter-tutorial-main/minter-starter-files/src/examplecache.png'

const Minter = (props) => {

  function addWalletListener() {
  if (window.ethereum) {
    window.ethereum.on("accountsChanged", (accounts) => {
      if (accounts.length > 0) {
        setWallet(accounts[0]);
        setStatus("👆🏽 Write a message in the text-field above.");
      } else {
        setWallet("");
        setStatus("🦊 Connect to Metamask using the top button.");
      }
    });
  } else {
    setStatus(
      <p>
        {" "}
        🦊{" "}
        <a target="_blank" href={`https://metamask.io/download.html`}>
          You must install Metamask, a virtual Ethereum wallet, in your
          browser.
        </a>
      </p>
    );
    }
  }

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [url, setURL] = useState("");
  const [wizstatus, setWizStatus] = useState(""); 
  const [wordguess, setWordGuess] = useState("");
  const [metacache, setMetacache] = useState("");
  const [status1, setcheck1] = useState("");
  const [status2, setcheck2] = useState("");
  const [status3, setcheck3] = useState("");
  const [status4, setcheck4] = useState("");
  const [status5, setcheck5] = useState("");


  useEffect(async () => { //TODO: implement
    const {address, status} = await getCurrentWalletConnected();
    setWallet(address);
    setStatus(status);
    const {Status1} = await check1();
    setcheck1(Status1)
    const {Status2} = await check2();
    setcheck2(Status2)
    const {Status3} = await check3();
    setcheck3(Status3)
    const {Status4} = await check4();
    setcheck4(Status4)
    const {Status5} = await check5();
    setcheck5(Status5)
    addWalletListener(); 
  }, []);

  const connectWalletPressed = async () => { //TODO: implement
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);

  };

  const onGuessMade = async (guess1) => {
    setWordGuess(guess1)
  }

  const onMetacacheChosen = async (guess) => {
    setMetacache(guess)
  }

  const onSubmitTxn = async() => {
    claimMagicWord(metacache, wordguess)
  }

  return (
    <div className="Minter">



       <div><h1>  POLYPIXA METACACHE </h1>	</div> 
   <br></br>






   <button id="walletButton" onClick={connectWalletPressed}>
        {walletAddress.length > 0 ? (
          "Connected: " +
          String(walletAddress).substring(0, 6) +
          "..." +
          String(walletAddress).substring(38)
        ) : (
          <span>Connect to Polygon</span>
        )}
      </button>

      <br></br>
            <h8> Find a metacache, enter the magic words on this site and receive a 100 $POLYPIXA reward!</h8>

            <h8> Where can you find a metacache? In the metaverse! Check out the cryptovoxels parcels owned by Pixa and search for those metacaches! Each metacache will have a magic word that will unlock the reward! There are 5 metacaches in total, and an example is shown below so you know what you're looking for!</h8>
               <br></br>


               
               
               <br></br>      
      <h4 id="subheading"> Cryptovoxels Links</h4>
      <div id = "webbutton">
      <form id="webbutton" action="https://www.voxels.com/parcels/4631">
        <input id="webbutton" type="submit" value="PIXAWIZARD PUB" />
      </form> 
      </div>

      <h4 id="subheading"> </h4>
      <div id = "webbutton">
      <form id="webbutton" action="https://www.voxels.com/parcels/5816">
        <input id="webbutton" type="submit" value="PIXAWITCH TAVERN" />
      </form> 
      </div>

      <h4 id="subheading"> </h4>
      <div id = "webbutton">
      <form id="webbutton" action="https://www.voxels.com/parcels/5390">
        <input id="webbutton" type="submit" value="PIXA COMMUNITY HUB" />
      </form> 
      </div>

      <br></br>  


               <br></br>
               <br></br>
               <h8>Example of what you're looking for:</h8>
               <div><h1>  <img src={example} alt="Image" height={200} width={200} style={{alignItems: 'Center'}} /> </h1>	</div>
                  <br></br>
     
            
      <br></br>

   
     <br></br>     <br></br>     
      <h3>🎱 ENTER MAGIC WORD 🎱</h3>    


      <input id="entry"
          type="number"
          placeholder="metacache # (1-5)"
          onChange={(event) => onMetacacheChosen(event.target.value)}
        />

<br></br>

      <input id="entry"
          type="text"
          placeholder="magic word"
          onChange={(event) => onGuessMade(event.target.value)}
        />




<br></br>






	    <button id="mintButton" onClick={onSubmitTxn}>
        Claim
      </button>
   





      <br>
      </br>

      <br>
      </br>
      
      <br>
      </br>
      
      <br>
      </br>
      <h4>💰 METACACHE STATUS 💰</h4>
<h5>#1 is {status1}</h5>

      <br>
      </br>
      <h5>#2 is {status2}</h5>
      <br>
      </br>
      <h5>#3 is {status3}</h5>
      <br>
      </br>
      <h5>#4 is {status4}</h5>
      <br>
      </br>
      <h5>#5 is {status5}</h5>
      <br>
      </br>
      <br>
      </br>





    </div>
  );
};



export default Minter;
